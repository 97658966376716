<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {};
  },
  // created () {},
  // mounted () {},
  methods: {
    goBackAccount() {
      if (this.$route.query.redirect) {
        this.$router.replace(this.$route.query.redirect);
      } else {
        this.$router.replace('/');
      }
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less"></style>
